import React from 'react';
import Leistungen from '../components/leistungen';
import Layout from '../components/layout'
import { graphql } from 'gatsby'

const Leistungen_ = ({ data }) => (
  <Layout title="Leistungen" >
    <Leistungen {...data} at={data.at} />
    {/* <Img key={data.leistungenYaml.fields.image.originalName} fluid={data.leistungenYaml.fields.image.childImageSharp.fluid} />
        <Link to={data.leistungenYaml.fields.path}>Project</Link>*/}
  </Layout>
)

export const query = graphql`
query LeistungenQuery {
    leistungenYaml {
      title
      subline
    }
  }

`

/*
export const query = graphql`
query LeistungenQuery {
    leistungenYaml {
      title
      subline
      fields {
          image {
              file {
                childImageSharp {
                    fluid {
                        aspectRatio
                            src
                            srcSet
                            sizes
                            originalImg
                            originalName
                            presentationWidth
                            presentationHeight
                    }
                }
            }
        }

      }
    }
  }

`
*/

export default Leistungen_
